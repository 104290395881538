import ReviewRajesh from '../img/reviews/review_rajesh.png';
import RevievGandhi from '../img/reviews/review_gandhi.png';
import RevievSadhguru from '../img/reviews/review_sadhguru.png';

export default {
    home: {
        name: "Головна",
        title: "Дошки Садху \nвід Euphoria System",
        subtitle: "Твій Ключ до Нового Виміру Усвідомленості"
    },
    about: {
        name: "Про те",
        title: "Що Таке Дошки Садху",
        description: {
            title: "Дошки Садху - це акупрессурний прилад для релаксації та відновлення",
            text: "Дошки для цвяхів — це інструменти йоги, які виникли в Індії понад 2000 років тому, також відомі як «Дошки Садху». Наші стопи та долоні мають тисячі енергетичних точок і нервових закінчень, пов’язаних з усіма внутрішніми органами та системами тіла.\n\n Стояння на дошках з цвяхами регулює нервову систему та тренує розум реагувати на будь-які стресові фактори зі стійким спокоєм і зосередженістю. Ефект від дошок можна порівняти з крижаною ванною, лише без холоду!\n\n Крім покращеня стресостійкості, дошки для цвяхів також пропонують великі фізичні переваги, такі як зняття м’язової напруги, покращення крово, та лімфообігу та вивільнення ендорфінів, які є природними гормонами гарного самопочуття."
        },
        banner: {
            title: "Медитація та уважність",
            text: "Стояння на цвяхах допомагає зосередити увагу на поточному моменті, підвищити концентрацію та досягти ясності розуму."
        }
    },
    materials: {
        name: "Матеріали",
        title: "Які матеріали ми використовуємо",
        divider: "А також",
        cooper: {
            title: "Мідь",
            text: "Мідь має високу стійкість до корозії та іржі. При використанні на відкритому повітрі мідні цвяхи менш схильні до руйнування з часом порівняно зі сталевими або залізними цвяхами. Завдяки своїм природним антимікробним властивостям він може допомогти пригнічувати ріст бактерій, грибків та інших мікроорганізмів.\n\nЦей чудовий провідник електрики має чудові естетичні якості та не потребує обслуговування. Завдяки стійкості до корозії мідні цвяхи вимагають мінімального догляду порівняно з іншими металами. Вони прослужать вам тривалий період без необхідності обслуговування.",
            button: "Замовити Мідні Цвяхи"
        },
        aluminium: {
            title: "Алюміній",
            text: "Алюміній є немагнітним, невибагливим у обслуговуванні, стійким до корозії матеріалом із надзвичайно легкою вагою, що робить ці цвяхи зручними у використанні та транспортуванні. Ця властивість особливо корисна під час подорожей. Дошки Садху з кроком 10 мм важать лише 288 г, і це вражаючий показник, враховуючи корисну площу, покриту цвяхами. \n\nЦі цвяхи природно стійкі до корозії, оскільки під впливом повітря алюміній утворює захисний оксидний шар. Така стійкість до корозії робить цей король цвяхів придатним для зовнішнього використання. Алюміній є чудовим провідником електрики. Ця властивість робить алюмінієві цвяхи придатними для практики на землі.",
            button: "Замовити Алюмінієві Цвяхи"
        },
        guide: {
            title: "Як обрати матеріал",
            text: "Вибір матеріалу є першим і таким же важливим, як і вибір кроку цвяха. Наша команда провела дослідження, під час якого наш інженер стояв на різних типах Дошок Садху протягом 182 днів – протягом години і більше. Він помітив різницю в сприйнятті. \n\nЦвяхи з міді суб'єктивно «м'якші» за всі інші види. Практика м’яка і плавна, навіть якщо ви стоїте годину або більше. \n\nАлюмінієві цвяхи - ідеальний вибір для тих, кому потрібна динамічність. Вага 288 г не заважає брати їх з собою всюди. Тільки уявіть, що ви залишилися на цвяхах на вершині гори (в експедиціях важлива загальна вага вашого багажу). Цей метал, як і мідь, є ідеальним провідником струму, що дозволяє використовувати для заземлення на природі Дошки Садху з відкритими цвяхами.",
            button: "Отримати консультацію"
        }
    },
    reviews: {
        name: "Відгуки",
        title: "Що Кажуть Люди",
        items: [
            {
            img: ReviewRajesh,
            title: 'Шрі Раджеш Махараджа',
            subtitle: 'Містик / Йог / Криптоінвестор',
            insta: '@rajeshsadhu',
            content: 'Дошки Садху від Euphoria System — найкраще, що траплялося зі мною з моменту моєї останнього народження в колесі сансари. Однозначно буду рекомендувати це своїм друзям Садху.'
          }, {
            img: RevievGandhi,
            title: 'Махатма Ганді',
            subtitle: 'Містик / Йог / Інфлюенсер',
            insta: '@gandiofficial',
            content: '«Ти маєш бути тією зміною, яку хочеш бачити у світі. Станьте цією зміною з дошками садху від Euphoria System. Я купив один комплект для свого друга Раджеша, подивимось що він скаже :)'
          }, {
            img: RevievSadhguru,
            title: 'Садгуру',
            subtitle: 'Mystic / Yogi / Influencer',
            insta: '@sadhguru.isha',
            content: 'Коли джерело змін перестає текти всередиті вас, ви станєте застійним басейном. Перетвори свій потік на гірську річку завдяки Дошкам Садху від Euphoria System. Оммм...'
          }
        ].reverse()
    },
    contacts: {
        name: "Контакти",
        title: "Зателефонуйте/Напишіть, щоб замовити",
        map: "Ми тут",
        delivery: {
            title: "Доставка Безкоштовна!",
            text: "Відправляємо по всьому світу, крім росії та партнерів. Доставка по Україні здійснюється компанією \"Нова пошта\" або \"Укр пошта\"."
        },
        pricing: {
            title: "Вартість",
            text: "Ціна вказана за набір дощок садху з алюмінієвими або мідними цвяхами на вибір."
        },
        banner: {
            title: "Потрібна допомога у підборі кроку та матеріалу цвяхів?",
            text: "Звʼяжіться з нами будь яким зручним для вас способом і наш спеціаліст з легкістю підбере правильну дошку під ваші потреби.",
            button: "Звʼязатись з нами"
        }
    },
    button: {
        order: "Замовити",
        more: "Детальніше"
    },
    menu: {
        home: "Головна",
        about: "Про дошки Садху",
        materials: "Матеріали Цвяхів",
        reviews: "Відгуки",
        contacts: "Контакти",
    }
}